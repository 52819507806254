@import "../../../public/assets/style/mixin.scss";

.under-construction-page {
  @include flexible("column", "center", "center");

  &_text-1 {
    width: 50vw;
  }

  &_text-2 {
    width: 20vw;
  }

  &_img {
    width: 35vw;
  }
}

.blocked-message {
  color: #ff4d4f !important;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  display: block;
  margin-top: 35px;
}
