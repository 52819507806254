@import "/public/assets/style/index.scss";

.pricing-page {
    height: 100%;
    // min-height: calc(100vh - 80px);
    @include flexible('', '', 'center');
    margin-top: 100px;

    .divider {
        border: 1px solid #FFFFFF33
    }

    .info-price,
    .info {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 5px;
    }

    .info-price {
        font-size: 20px;
        font-weight: 700;

        .price {
            color: $active_color;
            font-size: 30px;
        }
    }

    .register {
        color: $active_color;
        text-decoration: underline;
        cursor: pointer;
    }

    .subtitle,
    .title {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        color: #FFFFFF;
        margin: 20px 0 10px;
    }

    .subtitle {
        font-size: 16px;
        margin-bottom: 40px;
    }

    .button-link {
        text-align: center;
        margin: 30px 0;
    }

    .alert {
        color: $active_color;
        text-align: center;
        font-weight: 700;
    }
}

.res-packages-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(289px, 1fr));
    justify-content: center;
    gap: 40px;
    margin-bottom: 40px;
}


.res-package {
    @include flexible(column, '', 'center');
    border-radius: 8.773px;
    border: 1.462px solid rgba(255, 255, 255, 0.30);
    background: rgba(217, 217, 217, 0.10);
    width: 289px;
    height: 107px;
    padding: 10px 63px;

    .price-block,
    .name {
        color: #FFF;
        font-size: 17px;
        font-weight: 600;
    }

    .price-block {
        font-size: 12px;
        font-weight: 400;
    }
}

@media screen and (max-width: 1200px) {
    .res-packages-container {
        @include flexible('', 'center', 'center');
        flex-wrap: wrap;
    }

    .pricing-page-wrapper {
        width: 80%;
    }

}

@media screen and (max-width: 500px) {
    .name {
        font-size:13px !important;
    }  

    .price-block {
        font-size: 10px !important;
    }
}