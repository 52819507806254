.image-component {
    margin-bottom: 10px;

    .header-block {
        display: flex;

        .size {
            display: flex;

            input {
                width: 50px;
                height: 20px;
                margin: 0 5px;
                border: none;
                text-align: center;
                border-bottom: 1px solid #ccc;
                outline: none;
            }
        }
    }
}

.image-block {
    position: relative;

    &:hover {
        .remove-button {
            opacity: 1;
        }
    }
    .remove-button {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.682);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s;
        opacity: 0;
        font-size: 25px;
        color: white;
    }
}
