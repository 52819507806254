@import "../../styles/colors.scss";

.referring-pages {
  width: 800px !important;
  margin: auto;
  padding: 50px 0px;

  h1 {
    color: #dfe8eb;
    font-size: 28px;
  }

  p {
    color: #cad2d5 !important;
    font-size: 16px;
  }

  ul,ol {
    margin: 30px 20px !important;
    color: #cad2d5 !important;
  }

  .red-section {
    background-color: #ffb2b2;
    padding: 15px;
    margin: 30px 0px;
    border-radius: 2px;
    p {
      color: black !important;
      margin: 0;
    }
  }
}

@media screen and (max-width: 896px) {
  .referring-pages {
    width: 370px !important;
    padding: 20px;
    margin: auto;
    // text-align: justify;
  }
}
