@import "/public/assets/style/index";

.header {
    background: $navbar_background_color !important;
    @include flexible('', center, space-between);
    padding: 5px 40px;
    height: 80px;

    &_menu {
        display: flex;
        gap: 45px;
        align-items: center;
    }

    .menu-list__item__link {
        cursor: pointer;
        font-weight: 300;
        font-size: 18px;
        display: inline-block;
        position: relative;
        white-space: nowrap;
        color: $navbar_text_color !important;

        &:hover {
            color: $hover_color !important;
        }

        &::before {
            transition: 300ms;
            content: "";
            height: 2px;
            position: absolute;
            background-color: $hover_color;
            width: 0%;
            bottom: 10px;
        }

        &:hover::before {
            width: 100%;
        }
    }

    .menu-list__item__link.active-link {
        color: $active_color !important;

        &::after {
            content: "";
            position: absolute;
            height: 2px;
            width: 100%;
            background-color: $active_color;
            left: 0;
            bottom: 10px;
        }

        &:hover::before {
            width: 0;
        }
    }

    .btn {
        min-width: 140px;
        border: transparent;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;
        border-radius: 5px;
        color: white !important;
        transition: 0.2s;
        background-color: rgb(191, 67, 66);

        &:hover {
            background-color: #ee7b7a;

        }

    }
}

.logo {
    width: $logo_size;
    cursor: pointer;
}

.edit-modal {
    width: 400px;
}

.container,
.container-device-info {
    padding: 50px;
    height: 100%;
    background: rgba(103, 101, 113, 0.34);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    @include flexible(column, "", "");
}

.container-device-info {
    width: max-content;
    min-height: 137px;
    min-width: 336px;
    padding: 25px;
    margin-bottom: 50px;
    font-size: 18px;
    color: $text-color;
}

.device-info {
    @include flexible(column, "", "");
}

.categories-types {
    display: flex;
    gap: 50px;
}

.categories-container {
    @include flexible("", "", "");
    gap: 20px;
    margin-top: 40px;

    &__radio-btns {
        margin-bottom: 20px;

        .radio-btn {
            color: white !important;
            font-size: 17px;
        }
    }

    .single-categorie {
        @include flexible("", center, space-between);
        gap: 10px;

        &_hide-pin {
            @include flexible("", center, space-between);
            gap: 10px;

            button {
                cursor: pointer;
            }
        }
    }
}

.row {
    cursor: pointer;
}

.selected-row {
    background-color: #95d5b282;
    cursor: pointer;

    &:hover {
        background-color: #95d5b282 !important;
    }
}

.protected-playlist-checkbox {
    color: white;
    margin-bottom: 18px;
}

.container {
    padding: 17px;
}

@media screen and (max-width:600px) {
    .header_menu {
        gap: 15px !important;

        .menu-list__item__link {
            font-size: 15px;
        }
    }

    .logo {
        width: 50px;
    }

    .btn {
        min-width: 80px !important;
        font-size: 12px !important;
    }
}

@media screen and (max-width:800px) {
    .categories-container {
        @include flexible("", center, center);
        flex-wrap: wrap;
    }
}

@media screen and (max-width:600px) {
    .container-device-info {
        min-height: 108px;
        min-width: 259px;
        padding: 20px;
        font-size: 13px;
    }

    .edit-modal {
        width: 68vw;
    }
}

@media screen and (max-width:390px) {
    .save-btn {
        button {
            min-width: 100px !important;
        }
    }
}