@import "../../../../public//assets/style/variables.scss";
@import "../../../../public/assets/style/mixin.scss";

@import "../../../styles/colors.scss";

.header-main {
  position: relative;
}

.image-wrapper {
  // @include backgroundImage("../../../../public/assets/images/home/background.png");
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include flexible(column, "", center);
}

.container {
  width: 700px;
  margin: auto;

  .card-text {
    text-align: center;
    line-height: 1.1;

    h1 {
      font-size: 60px;
      color: $title_color !important;
      font-weight: bold;

      span {
        color: $primary-color;
      }
    }

    h2 {
      margin-top: 30px;
      color: $text-color;
      font-size: 24px;
      font-weight: 400;
    }
    h3 {
      margin-top: 30px;
      font-size: 18px;
      color: $text-color;
      font-weight: 400;
    }
  }

  .btn-container {
    width: 70%;
    margin: auto;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;

    button:nth-child(1) {
      background-color: $active-color;
      min-width: 200px;
    }

    button:nth-child(2) {
      background-color: white;
      border: 1px solid white;

      &:hover {
        color: white !important;
        border-color: transparent !important;
        background-color: rgba(255, 255, 255, 0.667) !important;
      }
    }

    button {
      font-size: 22px;
      height: 55px;
    }
  }
}

.news-section {
  position: relative;
  // background-image: url("../../../../public/assets/images/home/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 65px 0 50px;

  .news-container {
    width: 100%;
    margin: auto;
    max-width: 85%;
    margin: auto;

    .news-title {
      text-align: right;
      font-size: 48px;
      font-weight: bold;
      color: $title_color;
      margin-bottom: 22px;

      @media screen and (max-width: 1119px) {
        text-align: center;
      }

      @media screen and (max-width: 772px) {
        font-size: 30px;
      }

      span {
        color: $primary-color;
      }
    }

    .news-cards_wrapper {
      // grid-template-columns: repeat(auto-fit, minmax(444px, 444px));
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(385px, 385px));
      grid-gap: 32px;
      // justify-content: space-between;

      @media screen and (max-width: 1119px) {
        justify-content: center;
      }
    }

    .news-view_more {
      display: block;
      margin-top: 16px;
      font-weight: 700;
      font-size: 24px;
      text-align: right;
      color: $active-color;

      @media screen and (max-width: 772px) {
        font-size: 18px;
      }
    }
  }
}

.multi-functional_section,
.watch-live_section,
.find-content_section {
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

// @media screen and (max-width: 1600px) {
//     .news-section {
//         .news-container {

//             .news-cards_wrapper {
//                 // grid-template-columns: repeat(auto-fit, 1fr);
//                 grid-template-columns: repeat(2, 1fr);
//                 justify-content: space-between;
//             }
//         }
//     }
// }

@media screen and (max-width: 896px) {
  button {
    height: 50px;
  }
  .container {
    width: 80%;
    padding: 20px;

    .btn-container {
      width: 55%;
    }

    .card-text {
      h1 {
        font-size: 40px !important;
      }

      h2 {
        margin-top: 20px;
        color: $card-text-color;
        font-size: 24px;
        font-weight: 400;
      }
      h3 {
        margin-top: 20px;
        font-size: 18px;
        color: $card-text-color;
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: 772px) {
  .container {
    padding: 10px;
    width: 100%;

    .card-text {
      h1 {
        font-size: 35px !important;
      }

      h2 {
        font-size: 19px;
      }

      h3 {
        font-size: 16px;
      }
    }

    .btn-container {
      width: 65%;

      button {
        font-size: 19px;
        height: 50px;
      }
    }
  }
  
  .multi-functional_list {
    padding-left: 30px !important;
  }

  .news-section {
    .news-container {
      padding: 10px 40px;
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 90%;
  }
}
