@import "public/assets/style/variables.scss";

.button {
    width: 45px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    border-radius: 5px;
    color: $primary-dark;
    background-color: rgba(255, 255, 255, 0.783);
    transition: 0.2s;
    cursor: pointer;

    &:hover {
        background-color: white;
    }
}

.header {
    position: absolute;
    top: 100px;
    right: 30px;
}

.row {
    display: flex;
    gap: 10px;
}

.save-button {
    width: 120px;
    padding: 5px;
    margin: auto;
    background-color: $primary-color;
    color: $primary-dark;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        background-color: #0db43e;

        color: white;
    }
}

.settings-button {
    position: fixed;
    top: 45%;
    right: 0px;
    width: 55px;
    height: 55px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5f8ec4;
    color: white;
    font-size: 30px;
    z-index: 1;

    cursor: pointer;
    transition: 0.2s;

    &:hover {
        background-color: #4a7bb0;
    }

    &.navbar {
        top: 80px;
        bottom: -55px;
        right: 47%;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &.footer {
        position: absolute;
        top: -55px;
        right: 47%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0px;
    }
}

.color-picker {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    .color-block {
        width: 30px;
        height: 30px;
        border-radius: 3px;
        input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            background-color: transparent;
            opacity: 0;
            font-size: 20px;
            color: white;
        }
    }
}

.color-block {
    width: 25px;
    height: 25px;
    position: relative;
    background-color: red;

    &:hover {
        .delete-button {
            opacity: 1;
        }
    }
    .block {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .delete-button {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        position: absolute;
        background-color: white;
        opacity: 0;
        cursor: pointer;
        transition: 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

// .image-block {
//     position: relative;

//     &:hover {
//         .remove-button {
//             opacity: 1;
//         }
//     }
//     .remove-button {
//         width: 100%;
//         height: 100%;
//         background-color: rgba(0, 0, 0, 0.682);
//         position: absolute;
//         top: 0;
//         left: 0;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         transition: 0.2s;
//         opacity: 0;
//         font-size: 25px;
//         color: white;
//     }
// }
