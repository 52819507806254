@import "../../styles/colors.scss";
@import "public/assets/style/variables.scss";
@import "../../../public/assets/style/mixin.scss";

.page-container {
  padding: 2rem 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.405);
  background-color: $secondary-dark;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
}

.installation-guide-page-content {
  @include flexible("column", "center", "center");

  &_title {
    color: #25f662;
    font-size: 38px;
    font-weight: 700;
  }

  .installation-guide-page-container {
    @include flexible("", "center", "center");
    gap: 20px;

    .step-row {
      @include flexible("",baseline, "");

      .step {
        font-size: 28px;
        font-weight: 700;
        color: #ffffff;
        margin-right: 10px;
        @include flexible("", "", "");
        gap: 10px;
      }

      .text {
        color: #ffffff;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }

}

.images {
  @include flexible("", center, center);
  gap: 18px;
  margin: 70px 0;
}

.img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.preview{
  width: 1000px;
}

.platforms{
  width: 100%;
  margin-bottom: 70px;
}

@media screen and (max-width: 1080px) {

  .installation-guide-page-content_title,
  .step,
  .text {
    font-size: 16px !important;
  }

}

@media screen and (max-width: 760px) {
  .installation-guide-page-container {
    flex-direction: column;
    width: 300px;
  }

  .images {
    margin: 20px 0;
  }

  .platforms{
    margin-bottom: 0;
  }

  .step {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 500px) {
  .page-container {
    padding: 2rem !important;
  }
}

@media screen and (max-width: 700px) {


  .installation-guide-page-content_title,
  .text {
    font-size: 16px !important;
  }

}