@import "public/assets/style/mixin.scss";
@import "public/assets/style/variables.scss";

@import "../../styles/colors.scss";

.btn {
    min-width: 140px;
    border: transparent;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    color: $buttons_text_color !important;

    &:hover {
        background-color: $hover-color !important;
    }

    &:disabled {
        background-color: #b6b6b6a1;

        &:hover {
            background-color: #b6b6b6a1;
        }
    }
}

.large {
    width: 100%;
    height: 50px;
    background-color: $active-color;
    color: white !important;

    &:hover {
        opacity: 0.8;
        color: white !important;
    }

    &:focus {
        background-color: $active-color;
    }

    &:disabled {
        background-color: #b6b6b6a1;
        cursor: default;
        &:hover {
            background-color: #b6b6b6a1 !important;
        }
    }
}
