@import "../../../public/assets/style/mixin.scss";
@import "../../../public/assets/style/variables.scss";

.chat-wrapper{
  padding: 130px 0 36px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.chat-container {
  width: 60%;
  margin:auto;

  .title {
    border-radius: 8px 8px 0px 0px;
    padding: 14px 0 19px 17px;
    background: #4ce147;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
  }

  .start-content {
    width: 100%;
    @include flexible(column, center, center);
    height: 430px;
    background: #fff;

    .text {
      color: #adb2c1;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 49px;
      text-align: center;
    }
  }

  .messages {
    background-color: #fff;
    width: 100%;
    height: 430px;
    @include flexible(column, "", "");
    gap: 10px;
    overflow: auto;
    padding: 10px;
    padding-bottom: 26px;

    .message {
      padding: 10px 16px;
      border-radius: 8px;
      width: max-content;
      max-width: 55vw;
      word-break: break-all;

      &.admin {
        background: #f0f2f5;
        color: #a8adbd;
      }

      &.user {
        float: right;
        background: #073e4a;
        color: #fff;
      }
    }
  }

  .chat-nomessage-logo-content {
    background-image: url("../../images/chat.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    object-fit: cover;
    width: 143px;
    height: 143px;
    position: relative;
    @include flexible("", center, center);

    &__img {
      position: absolute;
      width: 50px;
      height: auto;
      // top: 29%;
      // left: 33%;
    }
  }

  .message-content {
    background: #ffffff;
    box-shadow: 0px -2px 36px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;
    position: relative;
    overflow-y: hidden;

    .message {
      padding: 24px 17px 0;
      width: 55vw;
      outline: none;
      border: none;
      resize: none;
    }

    .sent-btn {
      background-color: transparent;
      border: none;
      position: absolute;
      top: 20px;
      right: 21px;
      cursor: pointer;

      svg{
        fill:$active_color;
      }

      &:disabled {
        svg {
          fill: grey;
          cursor: not-allowed;
        }
      }
    }
  }
}

@media screen and (max-width: 776px) {
  .chat-container {
    width: 90%;

    .start-content,
    .messages {
      height: 453px !important;
    }
  }
}
