@import "../../../public/assets/style/mixin.scss";
@import "public/assets/style/variables.scss";

.languages-block {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    img {
        width: 25px;
    }

    span {
        font-size: 12px;
        margin-left: 10px;
        color: #fff;
    }
}

.languages-dropdown {
    background-color: $navbar_background_color;
    padding: 5px 10px;
    border-radius: 5px;
    width: max-content;

    img {
        width: 25px;
        margin-right: 10px;
    }

    li {
        color: white;

        span {
            font-size: 13px;
        }

        &:hover {
            background-color: transparent;
        }
    }
}

@media screen and (max-width: 896px) {
    .languages-block {
        span {
            display: none;
        }
    }
}
