@import "../../../public/assets/style/variables.scss";
@import "../../../public/assets/style/mixin.scss";
@import "../../styles/colors.scss";

.footer {
    width: 100%;
    // height: 80px;
    padding: 20px;
    position: relative;

    .email {
        font-size: 13px;
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            color: white;
            margin-left: 10px;
        }
    }

    .reserved {
        color: white;

        a {
            color: white;
            // margin-right: 10px;
        }
    }

    .version{
        color: white;
    }
}

.footer-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // width: 60%;
    margin: 0 auto;
}

.footer-navigation_wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: #fff;
    margin-bottom: 10px;

    a {
        font-size: 18px;
        font-weight: 300;
        color: white;
        white-space: nowrap;
    }

    a:not(:last-child) {
        margin-right: 40px;
    }
}

.copyright-wrapper {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social-links_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    gap: 10px;

    a {
        // &:not(:last-child) {
        //     margin-right: 20px;
        // }

        img {
            width: 30px;
            height: 30px;
            object-fit: contain;
        }
    }
}

.payments-cards {
    margin: 10px 0;
    width: 100%;
    text-align: center;

    img {
        width: 280px;
    }
}

@media screen and (max-width: 772px) {
    .footer-navigation_wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        a {
            font-size: 14px;
            margin-bottom: 10px;
            margin-right: 0 !important;
        }
    }
}
