.playlist-modal .ant-modal-content,
.key_or_code-modal .ant-modal-content {
  background: rgba(103, 101, 113, 0.34);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-modal-footer {
    border-top: none !important;
  }

  .playlist-modal-title,
  .delete-text,
  .key_or_code-modal-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 65px;
    font-size: 32px;
    color: #ffffff;
    // margin-bottom: 50px;
  }

  .delete-text {
    font-size: 23px;
  }
}

.ant-modal-confirm-body-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-modal-confirm-content {
    color: #ffffff !important;
    font-weight: 600;
    font-size: 18px;
  }

  .ant-btn.ant-btn-primary  {
    border-radius: 5px !important;

    background-color: rgb(191, 67, 66);
    color: rgb(255, 255, 255);
    border: transparent;
  }

  .ant-btn.ant-btn-default{
    border-radius: 5px !important;
    &:hover{
      border-color: transparent !important;
    color: black !important;

    }
    &:focus{
      border-color: transparent !important;
      color: black !important;
    }
  }
}

.ant-modal-mask{
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(50px);
}

.ant-modal-content {
  background-color: rgba(103, 101, 113, 0.34) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  backdrop-filter: blur(15px) !important;
  display: flex;
  justify-content: center;
  align-items: center;

}

.key_or_code-modal .ant-modal-content .ant-modal-body {
  // text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.playlist-edit-modal{
  width: 400px;
}

@media screen and (max-width:550px) {
  // .playlist-modal-container {
  //   width: 350px;
  // }

  .playlist-edit-modal{
    width: 290px;
  }
}

@media screen and (max-width:470px) {
  // .playlist-modal-container {
  //   width: 290px;
  // }
}