.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playlist-page-container {
  background: rgba(103, 101, 113, 0.34);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  margin: 100px auto;
  padding: 40px 74px;
  width: max-content;

  .url {
    width: 600px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .title {
    font-weight: 700;
    font-size: 35px;
    color: #ffffff;
    margin-bottom: 30px;
  }

  .single-playlist {
    padding: 18px 32px;
    background: #091522;
    opacity: 0.7;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;

    &__name-url {
      display: flex;
      align-items: center;
      gap: 59px;

      .name {
        font-weight: 600;
        font-size: 21px;
        color: #ffffff;
      }

      .url {
        color: #8d8e99;
        font-weight: 400;
        font-size: 20px;
      }
    }

    .edit-delete {
      display: flex;
      align-items: center;
      gap: 18px;

      .edit,
      .delete {
        cursor: pointer;
      }
    }
  }

  .playlist-add-btn {
    padding: 18px 39px;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 36px;
    background: #091522;
    opacity: 0.7;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 600;
    font-size: 21px;
    cursor: pointer;
  }
}

.add-modal-form,
.edit-modal-form {
  // width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

@media screen and (max-width: 1700px) {
  .title {
    font-size: 33px !important;
    margin-bottom: 18px !important;
  }

  .single-playlist {
    &__name-url {
      .name {
        font-size: 17px !important;
      }

      .url {
        font-size: 17px !important;
      }
    }

    .playlist-add-btn {
      gap: 13px !important;
      font-size: 18px !important;
    }
  }

  .playlist-add-btn {
    padding: 18px 21px !important;
    gap: 17px !important;
    font-size: 17px !important;
  }
}

.pin-modal-title {
  font-weight: 700;
  font-size: 23px;
  color: #ffffff;
  margin-bottom: 30px;
  text-align: center;
}

.pin-modal-form{
width: 350px;
}

.protected-playlist-checkbox{
  color: white;
  margin-bottom: 18px;
}

.device-info{
  color: #fff;
  font-size: 15px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1140px) {
  .playlist-page-container {
    padding: 40px;
  }

  .title {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 960px) {
  .url {
    width: 50vw !important;
  }
}

@media screen and (max-width: 720px) {
  .single-playlist__name-url {
    gap: 20px !important;
  }

  .url {
    width: 30vw !important;
  }

  .playlist-page-container {
    padding: 5vw !important ;
  }
}

@media screen and (max-width: 350px) {
  .add-modal-form,
  .edit-modal-form {
    width: 85vw !important;
  }
}

@media screen and (max-width: 430px) {
  .pin-modal-form{
    width: 65vw;
    }
}
