@import "public/assets/style/index.scss";

.message-open {
  font-size: 14px;
  overflow: hidden;
  height: 20px;
  transition: height 0.1s;
  @include flexible("", center, flex-start);
}

.message-close {
  transition: height 0.1s;
  overflow: hidden;
  height: 0;
  @include flexible("", center, flex-start);
}

.success-text {
  color:$success-color !important;
}

.error-text {
  color: $danger-color;
}
