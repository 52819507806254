.upload-playlist-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.protected{
  color: #fff;
  margin-bottom: 14px;
}
.upload-playlist-tabs{
  width: 400px;
  margin: 0 auto;
}

@media screen and (max-width: 772px) {
  .upload-playlist-container {
    width: 90%;
  }
}


@media screen and (max-width: 500px) {
  .upload-playlist-tabs{
    width: 85vw !important;
  }

  .ant-tabs.ant-tabs-top.ant-tabs-centered.upload-playlist-tabs.upload-playlist-tabs {
    width: 320px;
  }
}

// @media screen and (max-width:400px) {
//   .upload-playlist-tabs{
//     width: 290px !important;
//   }
// }