@import "../../../../public/assets/style/variables.scss";
@import "../../../styles/colors.scss";

.news-page {
    padding: 176px 0;

    .news-title {
        margin-bottom: 48px;
        font-weight: 700;
        font-size: 48px;
        color: $title_color;
    }

    .news-cards_main {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(385px, 385px));

        grid-gap: 32px;
    }
}

.current-news_main {
    padding-top: 131px;
    padding-bottom: 166px;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .back-to {
        margin-bottom: 26px;
        width: 24px;
        height: 24px;
        object-fit: contain;
        cursor: pointer;
    }
}

.current-news_wrapper {
    display: flex;
    background-color: $secondary-dark;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 8px;
    padding: 38px 38px 42px;
    margin-bottom: 106px;

    .image {
        width: 430px;
        height: 536px;
        object-fit: cover;
        // border-radius: 20px;
    }

    .info-wrapper {
        margin-left: 38px;
        // flex: 1;
        width: calc(100% - 430px - 38px);

        .info-head {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .title {
            max-width: 80%;
            font-weight: 700;
            font-size: 40px;
            color: white;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
        }

        .date {
            font-weight: 400;
            font-size: 16px;
            color: #ccc !important;
        }

        .description {
            font-size: 16px;
            color: #ffffff;
        }
    }
}

.related-news {
    .related-news_title {
        margin-bottom: 48px;
        font-weight: 400;
        font-size: 48px;
        color: #ffffff;
        text-transform: capitalize;
    }

    .related-new_wrapper {
        // grid-template-columns: repeat(auto-fit, minmax(444px, 444px));
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(385px, 385px));
        grid-gap: 32px;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1150px) {
    .current-news_main {
        padding-top: 100px;
        padding-bottom: 60px;
    }

    .current-news_wrapper {
        flex-direction: column;
        padding: 38px 38px 42px 38px;

        .image {
            width: 60%;
            height: 350px;
            margin-bottom: 38px;
        }

        .info-wrapper {
            margin-left: 0;
            width: 100%;

            .info-head {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 20px;
            }

            .title {
                font-size: 36px;
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 1002px) {
    .related-new_wrapper {
        justify-content: center !important;
    }

    .news-cards_main{
        justify-content: center;
    }
}

@media screen and (max-width: 772px) {
    .news-page {
        padding: 100px 0;

        .news-title {
            font-size: 36px;
            margin-bottom: 24px;
        }
    }

    .current-news_main {
        padding-bottom: 60px;

        .back-to {
            margin-bottom: 15px;
        }
    }

    .current-news_wrapper {
        padding: 24px 24px 28px 24px;

        .image {
            width: 100%;
            height: 250px;
            margin-bottom: 24px;
        }

        .info-wrapper {
            .info-head {
                margin-bottom: 12px;
            }

            .title {
                font-size: 24px;
            }
        }
    }

    .related-news {
        .related-news_title {
            font-size: 30px;
            margin-bottom: 24px;
        }

        .related-new_wrapper {
            // grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
            justify-content: center;
        }
    }
}
